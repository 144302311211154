import play from './img/Frame 27.svg';
import store from './img/Frame 28.svg';
import logo from './logo.png';
import mobilenav from './img/grid-fill.svg';

import AOS from 'aos';
import { Link } from "react-router-dom";
import 'aos/dist/aos.css';
import Partners from './partners';
import Careers from './careers';
import { useState } from 'react';

import cancel from './img/x-lg.svg';
import hm from './img/Frame 168 (2).svg';
import hmm from './img/Frame 168 (1).svg';
import hxm from './img/Frame 168.svg';


const About = () => {

    const currentYear = new Date().getFullYear();

    const [isElementVisible, setIsElementVisible] = useState(false);

    const handleClick = () => {
        setIsElementVisible(true);
    };


    const handleClickx = () => {
        setIsElementVisible(false);
    };
    

    AOS.init();


    return ( 

        <div>

            <section className="home pt-3">
                <div className="container">

                    <div className='d-flex justify-content-center align-items-center text-center'>
                        
                        
                        <div className='col-lg-4 col-6 text-start pt-3'>
                            <Link to="./" rel="noreferrer" className="text-decoration-none"> 
                                <div className='d-flex px-3 px-lg-0 mt-2'>
                                    <img src={logo} alt="logo" width="33" height="30" className='me-2'/>
                                    <h5 className='mt-1'>URBANPAY</h5>
                                </div>
                            </Link>
                        </div>

                        <div className='col-lg-5 mt-2 d-lg-block d-none justify-content-center text-center'>
                            <div className='d-flex align-items-center justify-content-between px-4 abtnavlink'>
                                <Link to='.././' rel="noreferrer" className="text-decoration-none text-white"><p className='mb-3 mt-3 me-2'>Home</p></Link>
                                <Link to='.././about' rel="noreferrer" className="text-decoration-none"><p className='abtactive mt-2 mb-2 me-2'>About</p></Link>
                                <Link to='https://blog.useurbanpay.com/' rel="noreferrer" target='_blank' className="text-decoration-none text-white"><p className='mt-3 mb-3 me-2'>Blog</p></Link>
                                <Link to='mailto:hello@useurbanpay.com' rel="noreferrer" className="text-decoration-none text-white"><p className='mt-3 mb-3 me-3'>Contact Us</p></Link>
                            </div>
                        </div>

                        <div className='col-lg-4 d-lg-block d-none text-end'>
                            <div className='mt-3'>
                                <Link to='https://dashboard.useurbanpay.com/signup' rel="noreferrer" target='_blank' className="text-decoration-none text-dark"><h6>Create an Account</h6></Link>
                            </div>
                        </div>


                        <div className='col-6 d-lg-none d-block text-end'>
                            <div className='mt-3'>
                                <img src={mobilenav} alt="" className='me-3' onClick={handleClick}/>
                            </div>
                        </div>

                    </div>

                    <div className="vh-100 align-items-center d-flex animate__animated animate__flipInX">
                        <div className="text-center justify-content-center m-auto">
                            <h1 className="display-1 mb-3 m-auto green">Simplifying payments to connect the world</h1>
                         
                            <p className="lead col-lg-7 col-11 m-auto">The team at UrbanPay is dedicated to move a step ahead to provide effortless payments for day-to-day activities and many more! </p>
                            
                                <div className='mt-4 pt-2 playico'>
                                    <button className='btn btn-lg bg-success text-white py-3 px-5'>Get in touch with us</button>
                                </div>
                        </div>                        
                    </div>
                </div>
            </section>


            {isElementVisible && 
            
                <section className='d-block d-lg-none navvb d-flex align-items-center py-5 fixed-top'>
                    <div className='container'>

                        <div className='d-flex'>
                        <div className='col-6 text-start px-3'>
                                <Link to=".././"><img src={logo} alt="" className='img-fluid' width="38"/> </Link>
                            </div>
                            

                            <div className='col-6 text-end'>
                                <img src={cancel} alt="" className='img-fluid me-4 mt-1' onClick={handleClickx}/>
                            </div>
                        </div>



                        <div className='py-5 mt-5 mb-4'>

                            <Link to=".././" className='text-decoration-none text-dark'>
                                <div className='d-flex px-3 mb-4'>
                                    <img src={hm} alt="" width="50" className='img-responsive me-5'/>
                                    <p className='lead mt-3 fw-bold'>Home</p>
                                </div>
                            </Link>

                            <Link to="mailto:hello@useurbanpay.com" className='text-decoration-none text-dark'>
                                <div className='d-flex px-3 mb-4'>
                                    <img src={hmm} alt="" width="50" className='img-responsive me-5'/>
                                    <p className='lead mt-3 fw-bold'>Contact</p>
                                </div>
                            </Link>


                            <Link to="./about" className='text-decoration-none text-dark'>
                                <div className='d-flex px-3 mb-4'>
                                    <img src={hxm} alt="" width="50" className='img-responsive me-5'/>
                                    <p className='lead mt-3 fw-bold active'>About Us</p>
                                </div>
                            </Link>


                        </div>
                    

                        <div className='text-center justify-content-center'>
                            <Link to="https://dashboard.useurbanpay.com/signin" target='_blank' className='text-decoration-none text-dark'><p className='lead'>Log In</p></Link>
                            <Link to="https://dashboard.useurbanpay.com/signup" target='_blank' className='text-decoration-none'><button className='btn px-5 py-3'>Create Account</button></Link>
                        </div>
                    </div>
                </section>
            }
        
            {<Partners/>}

            {<Careers/>}

            <section className='jumbo green col-lg-8 col-11 m-auto pt-5 pb-5 mb-5'>
                <div className='container'>
                    <div className='px-lg-5 px-4 mx-lg-5'>
                        <div className='col-12 text-center'>
                            <h3 className='display-6 pb-3 pt-2 col-lg-12 col-10 m-auto'>Know what we are up to</h3>
                            <p className=''>Follow us on our various socials and always get updates from us</p>

                                <div className='d-flex justify-content-between soc col-lg-6 m-auto text-center mt-4 pt-2'>
                                    <Link to="https://twitter.com/useurbanpay" target='_blank' className="text-dark"><p className='lead'>Twitter</p></Link>
                                    <Link to="https://instagram.com/useurbanpay" target='_blank' className="text-dark"><p className='lead'>Instagram</p></Link>
                                    <Link to="https://facebook.com/useurbanpay" target='_blank' className="text-dark"><p className='lead'>Facebook</p></Link>
                                </div>
                        </div>
                        
                    </div>
                </div>
            </section>


            <section className='footer pt-lg-5 pb-5 px-lg-0 px-3'>
                <div className='container'>

                    <div className='col-12 m-auto d-flex align-items-center justify-content-between'>

                        <div className='col-lg-3 me-5 d-none d-lg-block'>
                            <Link to="./" rel="noreferrer" className="text-decoration-none"> 
                                <div className='d-flex log'>
                                    <img src={logo} alt="logo" width="40" height="40" className='me-2'/>
                                    <h4 className='mt-2 mb-5'>URBANPAY</h4>                                        
                                </div>
                            </Link>

                                <div className='d-block'>
                                    <Link to="./" rel="noreferrer" className="text-decoration-none"> 
                                         <img src={store} alt="" width="260" className='img-fluid'/>
                                    </Link>
                                    <br/><br/>
                                    <Link to="./" rel="noreferrer" className="text-decoration-none"> 
                                        <img src={play} alt="" width="260" className='img-fluid'/>
                                    </Link>
                                </div>
                            
                        </div>

                        <div className='col-lg-3 col-6 me-lg-4'>
                            <h3 className='mb-4'>Product</h3>
                            <Link to="./transfer" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Transfer Money</h5></Link>
                            <Link to="./transport" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Transport Pricing</h5></Link>
                            <Link to="./cards" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Virtual Cards</h5></Link>
                            <Link to="./exchange" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Currency Exchange</h5></Link>
                        </div>

                        <div className='col-lg-3 col-6 me-lg-4 px-lg-0 mx-lg-0 px-5 mx-4'>
                            <h3 className='mb-4'>Company</h3>
                            <Link to="./contact" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Contact</h5></Link>
                            <Link to="./careers" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Careers</h5></Link>
                            <Link to="./about" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>About</h5></Link>
                            <Link to="./blog" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Blog</h5></Link>
                        </div>

                        <div className='col-lg-3 col-12 d-none d-lg-block'>
                            <h3 className='mb-4'>Connect</h3>
                            <a href="https://twitter.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Twitter</h5></a>
                            <a href="https://instagram.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Instagram</h5></a>
                            <a href="https://linkedin.com/company/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Linkedin</h5></a>
                            <a href="https://facebok.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Facebook</h5></a>
                        </div>
                    </div>


                        <div className='col-lg-3 col-12 mt-5 d-block d-lg-none'>
                            <h3 className='mb-4'>Connect</h3>
                            <a href="https://twitter.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Twitter</h5></a>
                            <a href="https://instagram.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Instagram</h5></a>
                            <a href="https://linkedin.com/company/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Linkedin</h5></a>
                            <a href="https://facebok.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Facebook</h5></a>
                        </div>



                    <div className='col-12 m-auto mt-5 pt-3 mb-5 foot'>
                        <p className='mt-3 text-muted'>UrbanPay is a financial technology company, not a bank. We partners with licensed transmitters to offer products and services. 
                        </p>

                        <p className='mt-3 text-muted'>Trademarks and brand names belong to respective owners; their use does not imply endorsement.</p>

                        <p className='mt-3 text-muted'>Important: To comply with federal law, we require identification information when opening an account, including name, address, date of birth, and possibly a driver's license copy.</p>

                        <p className='mt-5 text-muted'>&copy; UrbanPay {currentYear} - All rights reserved </p>
                    </div>

                </div>
            </section>

            
        </div>

     );
}
 
export default About;

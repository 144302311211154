import play from './img/Frame 27.svg';
import store from './img/Frame 28.svg';
import logo from './logo.png';

import { Link } from "react-router-dom";

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return ( 
        <div>
            
            <section className='footer pt-lg-5 pb-5 px-lg-0 px-3'>
                <div className='container'>

                    <div className='col-12 m-auto d-flex align-items-center justify-content-between'>

                        <div className='col-lg-3 me-5 d-none d-lg-block'>
                            <Link to="./" rel="noreferrer" className="text-decoration-none"> 
                                <div className='d-flex log'>
                                    <img src={logo} alt="logo" width="40" height="40" className='me-2'/>
                                    <h4 className='mt-2 mb-5'>URBANPAY</h4>                                        
                                </div>
                            </Link>

                                <div className='d-block'>
                                    <Link to="./" rel="noreferrer" className="text-decoration-none"> 
                                         <img src={store} alt="" width="260" className='img-fluid'/>
                                    </Link>
                                    <br/><br/>
                                    <Link to="./" rel="noreferrer" className="text-decoration-none"> 
                                        <img src={play} alt="" width="260" className='img-fluid'/>
                                    </Link>
                                </div>
                            
                        </div>

                        <div className='col-lg-3 col-6 me-lg-4'>
                            <h3 className='mb-4'>Product</h3>
                            <Link to="./transfer" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Transfer Money</h5></Link>
                            <Link to="./transport" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Transport Pricing</h5></Link>
                            <Link to="./cards" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Virtual Cards</h5></Link>
                            <Link to="./exchange" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Currency Exchange</h5></Link>
                        </div>

                        <div className='col-lg-3 col-6 me-lg-4 px-lg-0 mx-lg-0 px-5 mx-4'>
                            <h3 className='mb-4'>Company</h3>
                            <Link to="./contact" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Contact</h5></Link>
                            <Link to="./careers" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Careers</h5></Link>
                            <Link to="./about" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>About</h5></Link>
                            <Link to="./blog" rel="noreferrer" className="text-decoration-none text-dark"><h5 className='mb-4'>Blog</h5></Link>
                        </div>

                        <div className='col-lg-3 col-12 d-none d-lg-block'>
                            <h3 className='mb-4'>Connect</h3>
                            <a href="https://twitter.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Twitter</h5></a>
                            <a href="https://instagram.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Instagram</h5></a>
                            <a href="https://linkedin.com/company/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Linkedin</h5></a>
                            <a href="https://facebok.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Facebook</h5></a>
                        </div>
                    </div>


                        <div className='col-lg-3 col-12 mt-5 d-block d-lg-none'>
                            <h3 className='mb-4'>Connect</h3>
                            <a href="https://twitter.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Twitter</h5></a>
                            <a href="https://instagram.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Instagram</h5></a>
                            <a href="https://linkedin.com/company/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Linkedin</h5></a>
                            <a href="https://facebok.com/useurbanpay" className="text-decoration-none text-dark" target='_blank' rel='noreferrer'><h5 className='mb-4'>Facebook</h5></a>
                        </div>



                    <div className='col-12 m-auto mt-5 pt-3 mb-5 foot'>
                        <p className='mt-3 text-muted'>UrbanPay is a financial technology company, not a bank. We partners with licensed transmitters to offer products and services. 
                        </p>

                        <p className='mt-3 text-muted'>Trademarks and brand names belong to respective owners; their use does not imply endorsement.</p>

                        <p className='mt-3 text-muted'>Important: To comply with federal law, we require identification information when opening an account, including name, address, date of birth, and possibly a driver's license copy.</p>

                        <p className='mt-5 text-muted'>&copy; UrbanPay {currentYear} - All rights reserved </p>
                    </div>

                </div>
            </section>

        </div>
     );
}
 
export default Footer;
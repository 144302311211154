import gotv from './img/partners/download-removebg-preview.png';
import dstv from './img/partners/partners31.52ffda50.png';
import star from './img/partners/partners36.ca8ab0b2.png';
import bet from './img/partners/partners37.08f64949.png';
import ibedc from './img/partners/Head-Legal-at-Ibadan-Electricity-Distribution-Company-IBEDC-Plc-removebg-preview.png';
import ike from './img/partners/download__1_-removebg-preview.png';
import airtel from './img/partners/partners32.5458a46c.png';
import mob from './img/partners/partners34.7e40f413.png';
import glo from './img/partners/partners35.d853c155.png';
import mtn from './img/partners/partners33.af0c8183.png';

const Partners = () => {
    return ( 

        <div>
             <section className='partners pb-lg-5 mb-lg-5'>
                <div className='container'>
                    <div className='pt-lg-5 mt-lg-5 pt-5 mt-5'>

                        <div className='mt-lg-3 text-center pt-lg-3 pb-5 mb-5'>
                            <h1 className='display-2 col-10 mb-5 pb-5 m-auto'> Our Partners </h1>
                            

                                <div className='col-12 mt-4 text-center m-auto justify-content-between'>
                                    <img src={gotv} alt="" width="150" className='img-fluid me-5 mb-lg-0 mb-4 pb-4'/>
                                    <img src={dstv} alt="" width="150" className='img-fluid me-5 mb-lg-0 mb-4 pb-4'/>
                                    <img src={star} alt="" width="150" className='img-fluid me-5'/>
                                    <img src={bet}  alt="" width="120" className='img-fluid me-5'/>
                                                                   
                                </div>

                                <div className='col-12 mt-5 text-center m-auto  justify-content-between d-none d-lg-block'>
                                    <img src={ike} alt=""  width="150" className='img-fluid' /> 
                                    <img src={ibedc} alt="" width="90" className='img-fluid me-5' />
                                    <img src={glo} alt="" width="70" className='img-fluid me-5' />
                                    <img src={mtn} alt="" width="90" className='img-fluid me-5' />
                                    <img src={mob} alt="" width="50" className='img-fluid me-5' />
                                    <img src={airtel} alt="" width="90" className='img-fluid me-5' />
                                </div>

                                
                        </div>


                    </div>
                </div>
            </section>

        </div>
     );
}
 
export default Partners;
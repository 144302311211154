import play from './img/Frame 27.svg';
import store from './img/Frame 28.svg';
import logo from './logo.png';
import spend from './img/image 9.svg';
import gif from './img/money.gif';
import fake from './img/3d 1.svg';
import trans from './img/fotor-ai-202305262550-removebg-preview 1.svg';
import mobilenav from './img/grid-fill.svg';

import { TypeAnimation } from 'react-type-animation';
import AOS from 'aos';
import { Link } from "react-router-dom";
import 'aos/dist/aos.css';
import { useState } from 'react';

import cancel from './img/x-lg.svg';
import hm from './img/Frame 168 (2).svg';
import hmm from './img/Frame 168 (1).svg';
import hxm from './img/Frame 168.svg';


import Partners from './partners';
import Footer from './footer';



const Homepage = () => {

    const [isElementVisible, setIsElementVisible] = useState(false);

    const handleClick = () => {
        setIsElementVisible(true);
    };


    const handleClickx = () => {
        setIsElementVisible(false);
    };
    
    

    

    AOS.init();

    return ( 
        <div>

            <section className="home pt-3">
                <div className="container">

                    <div className='d-flex justify-content-center align-items-center text-center'>
                        
                        
                        <div className='col-lg-4 col-6 text-start pt-3'>
                            <Link to="./" rel="noreferrer" className="text-decoration-none"> 
                                <div className='d-flex px-3 px-lg-0 mt-2'>
                                    <img src={logo} alt="logo" width="33" height="30" className='me-2'/>
                                    <h5 className='mt-1'>URBANPAY</h5>
                                </div>
                            </Link>
                        </div>

                        <div className='col-lg-5 mt-2 d-lg-block d-none justify-content-center text-center'>
                            <div className='d-flex align-items-center justify-content-between px-2 navlink'>
                                <Link to='.././' rel="noreferrer" className="text-decoration-none"><p className='active mt-2 mb-2 me-2'>Home</p></Link>
                                <Link to='./about' rel="noreferrer" className="text-decoration-none text-dark"><p className='mt-3 mb-3 me-2'>About</p></Link>
                                <Link to='https://blog.useurbanpay.com/' rel="noreferrer" target='_blank' className="text-decoration-none text-dark"><p className='mt-3 mb-3 me-2'>Blog</p></Link>
                               <Link to='mailto:hello@useurbanpay.com' rel="noreferrer" className="text-decoration-none text-dark"><p className='mt-3 mb-3 me-3'>Contact Us</p></Link>
                            </div>
                        </div>

                        <div className='col-lg-4 d-lg-block d-none text-end'>
                            <div className='mt-3'>
                                <Link to='https://dashboard.useurbanpay.com/signup' rel="noreferrer" target='_blank' className="text-decoration-none text-dark"><h6>Create an Account</h6></Link>
                            </div>
                        </div>


                        <div className='col-6 d-lg-none d-block text-end'>
                            <div className='mt-3'>
                                <img src={mobilenav} alt="" className='me-3' onClick={handleClick}/>
                            </div>
                        </div>

                    </div>

                    <div className="vh-100 align-items-center d-flex animate__animated animate__fadeIn">
                        <div className="text-center justify-content-center m-auto">
                            <h1 className="display-1 m-auto">Don't <span><TypeAnimation sequence={['bother ', 2000, 'pester ', 2000, 'stress ', 2000]} cursor={false} repeat={Infinity}/></span> about</h1>
                            <h1 className="display-1 m-auto mb-3"><span>borders</span> anymore</h1>
                            <p className="lead col-lg-7 col-11 m-auto">Welcome to a seamless borderless payment future where simplicity and global connectivity are just a click away </p>
                            
                                <div className='mt-4 pt-2 playico'>
                                    <img src={play} alt="" width="260" className='img-fluid me-4 mb-lg-0 mb-3'/>
                                    <img src={store} alt="" width="260" className='img-fluid me-4'/>
                                </div>
                        </div>                        
                    </div>
                </div>
            </section>


            {isElementVisible && 
            
                <section className='d-block d-lg-none navvb d-flex align-items-center py-5 fixed-top'>
                    <div className='container'>

                        <div className='d-flex'>
                        <div className='col-6 text-start px-3'>
                                <Link to=".././"><img src={logo} alt="" className='img-fluid' width="38"/> </Link>
                            </div>
                            

                            <div className='col-6 text-end'>
                                <img src={cancel} alt="" className='img-fluid me-4 mt-1' onClick={handleClickx}/>
                            </div>
                        </div>



                        <div className='py-5 mt-5 mb-4'>

                            <Link to=".././" className='text-decoration-none text-dark'>
                                <div className='d-flex px-3 mb-4'>
                                    <img src={hm} alt="" width="50" className='img-responsive me-5'/>
                                    <p className='lead mt-3 fw-bold active'>Home</p>
                                </div>
                            </Link>

                            <Link to="mailto:hello@useurbanpay.com" className='text-decoration-none text-dark'>
                                <div className='d-flex px-3 mb-4'>
                                    <img src={hmm} alt="" width="50" className='img-responsive me-5'/>
                                    <p className='lead mt-3 fw-bold'>Contact</p>
                                </div>
                            </Link>


                            <Link to="./about" className='text-decoration-none text-dark'>
                                <div className='d-flex px-3 mb-4'>
                                    <img src={hxm} alt="" width="50" className='img-responsive me-5'/>
                                    <p className='lead mt-3 fw-bold'>About Us</p>
                                </div>
                            </Link>


                        </div>
                    

                        <div className='text-center justify-content-center'>
                            <Link to="https://dashboard.useurbanpay.com/signin" target='_blank' className='text-decoration-none text-dark'><p className='lead'>Log In</p></Link>
                            <Link to="https://dashboard.useurbanpay.com/signup" target='_blank' className='text-decoration-none'><button className='btn px-5 py-3'>Create Account</button></Link>
                        </div>
                    </div>
                </section>
                }


            



            <section className='features mb-lg-5 pb-lg-5'>
                <div className='container'>
                    
                    <div className='d-lg-flex feature-1 px-lg-0 px-3'>
                        <div className='col-lg-6 d-none d-lg-block col-12 mt-3 spends animate__animated animate__bounceInLeft' data-aos="fade-right">
                            <img src={spend} alt="" width="90%" className='img-fluid'/>
                        </div>

                        <div className='col-lg-6 col-12 mt-3 pt-3 yourmoney' data-aos="fade-left">
                            <h1 className='display-4 col-12 mb-4'> It is <span>your mo₦ey.</span> $pend it your way.</h1>
                            <p className='col-lg-10 col-11 mb-4 lead mt-lg-0 mt-3'>We respect your funds and never interfere with your financial matters. Your monies are sacred to us. </p>
                            <Link to='https://dashboard.useurbanpay.com/signup' rel="noreferrer" target='_blank' className="text-decoration-none text-dark"><button className='btn py-3 px-5' data-aos="fade-up-right">Get Started with us Now</button></Link>
                        </div>


                        <div className='d-block d-lg-none col-12 mt-5 pt-3 text-center spends'>
                            <img src={spend} alt="" width="90%" className='img-fluid'/>
                        </div>

                    </div>


                    <div className='d-lg-flex pt-lg-5 mt-lg-5 pt-0 feature-2'>

                        <div className='col-lg-6 col-12 mt-lg-3 pt-lg-3 mt-0 pt-0 px-lg-0 px-3' data-aos="flip-up">
                            <h1 className='display-4 col-lg-11 col-12 mb-4'> Get notified of <span>fake transactions</span> in seconds</h1>
                            <p className='col-10 mb-4 lead'>We notify you when a fake transaction has been detected through our system’s continuous analysis </p>
                            <Link to='https://dashboard.useurbanpay.com/signin' rel="noreferrer" target='_blank' className="text-decoration-none text-dark"><button className='btn py-3 px-5' data-aos="fade-up-right">Secure my transactions</button></Link>
                        </div>


                        <div className='col-lg-6 col-12 mt-lg-3 mt-5' data-aos="zoom-in-up">
                            <img src={fake} alt="" width="90%" className='img-fluid'/>
                        </div>

                        

                    </div>


                    <div className='pt-lg-5 mt-lg-5 pt-0 feature-3 px-lg-0 px-3'>

                        <div className='mt-3 pt-3 trns'>
                            <h1 className='display-4 col-lg-10 col-12 mb-4 m-auto' data-aos="zoom-in-down"> Get real-time <span>local and commercial</span> transport fares</h1>
                            <p className='col-lg-9 col-12 mb-0 lead m-auto' data-aos="fade-down-right">You can get real-time prices for both local and commercial vehicles. This enables you stay up-to-date with transport pricing to plan yourself accordingly </p>
                                <div className='mb-2' data-aos="zoom-in-right" data-aos-duration="3000">
                                    <img src={trans} alt="" width="600" className='img-fluid'/>
                                </div>
                                <Link to='https://dashboard.useurbanpay.com/signin' rel="noreferrer" target='_blank' className="text-decoration-none text-dark"><button className='btn py-3 px-5' data-aos="fade-up-right">View today's transport price</button></Link>
                        </div>
                        

                    </div>


                    <div className='d-lg-flex feature-4 px-lg-0 px-3'>

                        <div className='col-lg-6 col-12 mt-lg-3'>
                            <img src={gif} alt="" width="100%" className='img-fluid'/>
                        </div>

                        <div className='col-lg-6 col-12 mt-3 pt-3'>
                            <h1 className='display-4 col-lg-12 col-10 mb-4'> <span>one CARD + one WALL£T = </span> Multiple Transaction$ </h1>
                            <p className='col-lg-10 col-12 mb-4 lead'>Say goodbye to juggling various wallets and effortlessly handle your currencies with ease on a single platform. Experience the freedom and flexibility </p>
                            <button className='btn py-3 px-5 d-lg-none'>Get Started with us Now</button>
                            <div className='d-lg-flex mt-4 pt-2 d-none'>
                                    <img src={play} alt="" width="260" className='img-fluid me-4'/>
                                    <img src={store} alt="" width="260" className='img-fluid me-4'/>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            {<Partners/>}


            <section className='jumbo col-lg-8 col-11 m-auto pt-5 pb-5 mb-5'>
                <div className='container'>
                    <div className='px-lg-5 px-4 mx-lg-5'>
                        <div className='col-12'>
                            <h3 className='display-6 pb-3 pt-2'>Join the Urbanized family now</h3>
                            <p className=''>Experience the ease of creating an account and dive 
                                straight into accepting payments, showcasing your 
                                magnificent products online.</p>

                                <div className='mt-4 pt-2'>
                                    <img src={play} alt="" width="260" className='img-fluid me-4 mb-lg-0 mb-3'/>
                                    <img src={store} alt="" width="260" className='img-fluid me-4'/>
                                </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            {<Footer/>}


            
        </div>
     );
}
 
export default Homepage;

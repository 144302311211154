import { Link } from "react-router-dom";

const Careers = () => {
    return ( 
        <div>
             <section className='careers pb-lg-5 mb-lg-5'>
                <div className='container'>
                    <div className='pt-lg-5 mt-lg-5 pt-5 mt-5'>

                        <div className='mt-lg-3 text-center pt-lg-3 pb-5 mb-5'>
                            <h1 className='display-2 col-10 mb-4 m-auto'> Careers </h1>
                            
                                <p className="lead m-auto mb-2">Want to join us?</p>
                                <p className="lead col-lg- m-auto">Check out available positions at the UrbanPay team <Link to >here</Link></p>
                                
                        </div>


                    </div>
                </div>
            </section>
        </div>
     );
}
 
export default Careers;
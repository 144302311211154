import './css/style.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';

import Homepage from './home';
import About from './about';


function App() {

  const handleOnline = () => {
      
    toast.dismiss();

    toast("internet Restored 🚀", {
        position: "top-right",
        autoClose: 3000,
        transition: Slide,
        hideProgressBar: true,
        theme: "info",
    });

  }


  const handleOffline = () => {

    toast.dismiss();

    toast("No/Bad internet connection 😭", {
        position: "top-right",
        autoClose: 6000,
        transition: Slide,
        hideProgressBar: true,
        theme: "danger",
    });

  }


  useEffect(() => {
    //const handleOnline = () => setIsOnline(true);
    //const handleOffline = () => setIsOnline(false);0 

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
           <Route path='/' element={<Homepage/>}/>
           <Route path='/about' element={<About/>}/>
        </Routes>
      
        <ToastContainer/>
    
      </div>
    </Router>
  );
}

export default App;